module.exports = {
    "防骗指南": "Hướng dẫn phòng tránh lừa đảo",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "Tất cả tài nguyên trên nền tảng này đều tuân thủ chế độ đăng ký tên thật, cả quý cô và quý ông đều là thành viên VIP, vui lòng duy trì sự tôn trọng đôi bên và không nên nói lời không lịch sự và thiếu văn hóa. Nếu phát hiện, việc hủy quyền hẹn gặp sẽ được thực hiện. Nhấn mạnh điều này ba lần!!!",
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "Quý cô không thu bất kỳ phí gì, chỉ cần hoàn thành ba nhiệm vụ đánh giá trên nền tảng để nhận quyền hẹn gặp và trải nghiệm tài nguyên hẹn hò cùng thành phố. Chi phí thuê phòng phải tự trả đấy!",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "Cô gái đã đến tầng dưới, yêu cầu thanh toán 【toàn bộ tiền mới có thể lên phòng】 đều là lừa đảo không nghi ngờ, vui lòng liên hệ với dịch vụ tiếp đãi trên nền tảng ngay lập tức để báo cáo!",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.0001.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "Lời nhắc bạn bè: Giáo viên, nhân viên tiếp đón, người giao hàng đều không được phép thêm thông tin liên hệ cá nhân của khách hàng. Thời gian làm việc là từ 13.0001.00, ngoài giờ này không tiếp nhận dịch vụ. Nếu có ai đó gửi tin nhắn cho bạn trong thời gian không làm việc, vui lòng không tin tưởng để tránh mất tiền. Chúng tôi không chịu trách nhiệm!!!",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "Bất kỳ ai yêu cầu bạn tải xuống phần mềm khác đều là lừa đảo, vui lòng chụp ảnh màn hình ngay lập tức và liên hệ với dịch vụ tiếp đãi trên nền tảng để báo cáo!",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "Nền tảng này chịu trách nhiệm đối với trải nghiệm tìm kiếm của mỗi thành viên VIP. Nếu gặp bất kỳ vấn đề nào, vui lòng liên hệ với dịch vụ tiếp đãi trên nền tảng để báo cáo ngay lập tức. Sau khi xác minh chính thức, chúng tôi sẽ hoàn trả chi phí mở khóa!",
    "预约大厅": "Lễ tân đặt lịch",
    "会员视频": "Video thành viên",
    "骑在身上大屌套弄": "Cưỡi trên người, dương vật lớn, làm tình tự nhiên",
    "选妃中心": "Trung tâm lựa chọn phi tần",
    "新加入": "Thành viên mới gia nhập",
    "附近的人": "Người ở gần",
    "为您发现同城炮友 999+ 位": "Dành cho bạn khám phá bạn đồng hương hẹn hò cùng thành phố 999+ người",
    "刷新成功": "Làm mới thành công",
    "为您发现同城炮友": "Dành cho bạn khám phá bạn đồng hương hẹn hò cùng thành phố",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "Tất cả tài nguyên trên nền tảng này đều thật, an toàn, đáng tin cậy và được triển khai trên toàn quốc. Chọn người mà bạn thích và bắt đầu hẹn hò bất cứ lúc nào, bất cứ nơi đâu~",
    "联系专属接待客服": "Liên hệ với dịch vụ tiếp đãi riêng",
    "开卡可见": "Hiển thị khi mở khóa",
    "寶衡传媒打分": "Điểm đánh giá của Phương tiện truyền thông Bảo Hiểm",
    "美女颜值": "Giá trị nhan sắc của cô gái đẹp",
    "详细地址：已隐藏 联系小蜜开卡可见": "Địa chỉ chi tiết: Đã ẩn. Liên hệ với chúng tôi để mở khóa và xem.",
    "联系方式：已隐藏 联系小蜜开卡可见": "Thông tin liên hệ: Đã ẩn. Liên hệ với chúng tôi để mở khóa và xem.",
    "基本信息 20岁,身高 175": "Thông tin cơ bản {age} tuổi, chiều cao {height}",
    "所在地区": "Khu vực đang ở",
    "服务项目 激活可见": "Dự án dịch vụ. Hiển thị khi kích hoạt.",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "Chúc các thành viên, hẹn hò với cô gái đẹp mỗi ngày, và có thể làm chú rể mỗi đêm!",
    "立即约她": "Hẹn cô ấy ngay",
    "请联系专属客服接待": "Vui lòng liên hệ với dịch vụ tiếp đãi riêng",
    "寶衡传媒": "Phương tiện truyền thông Bảo Hiểm",
    "华人第一福利品牌": "Thương hiệu phúc lợi hàng đầu cho người Hoa",
    "为了会员权益，网站只展示": "Để bảo vệ quyền lợi của thành viên, trang web chỉ hiển thị",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "Tài nguyên và được cập nhật không định kỳ chỉ để trưng bày sức mạnh. Để tìm kiếm thêm tài nguyên, vui lòng liên hệ với tiếp viên cấp trên.",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "Tất cả tài nguyên trên nền tảng này đều thật, an toàn, đáng tin cậy và triển khai trên toàn quốc. Chọn người bạn yêu thích và bắt đầu hẹn hò từng bước, hãy nhanh chóng tham gia với chúng tôi!",
    "寶衡资源": "Tài nguyên Phương tiện truyền thông Bảo Hiểm",
    "昵称": "Biệt danh",
    "类型": "Loại",
    "服务项目: 激活可见": "Dự án dịch vụ: Hiển thị khi kích hoạt",
    "已认证": "Đã xác minh",
    "实时配对": "Kết hợp thời gian thực",
    "约炮心得": "Kinh nghiệm hẹn hò",
    "服务条款": "Điều khoản dịch vụ",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "Nền tảng này cung cấp trải nghiệm hẹn hò ngoài giờ thường, hẹn hò doanh nhân và đam mê cùng thành phố. Để đảm bảo quyền riêng tư của mỗi người dùng, chỉ có khách hàng thông qua tiếp viên hoặc thành viên cấp cao trên nền tảng được giới thiệu theo tên mới được phép tham gia.",
    "有哪些资源?": "Có những tài nguyên nào?",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "Người nổi tiếng trực tuyến, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học - chỉ cần bạn có ý định, nền tảng này có thể đáp ứng tất cả!",
    "服务范围": "Phạm vi dịch vụ",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "Dự án dịch vụ: Quan hệ tình dục 3 lần, có thể không đội bao, blowjob sâu, du lịch tình dục, giả vờ làm tình, masage lừa dối",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "Có thể chụp ảnh kỷ niệm: Chụp riêng vùng kín, quay phim quan hệ tình dục, quay phim blowjob",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "Có thể chọn trang phục: Y tá - Hầu gái - Tiếp viên hàng không - Thư ký - Nữ cảnh sát - Người mèo",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "Có thể buộc và nhẹ nhàng tra tấn: Sử dụng đồ chơi tình dục nam nữ đa dạng",
    "北京": "Bắc Kinh",
    "上海": "Thượng Hải",
    "广州": "Quảng Châu",
    "深圳": "Thâm Quyến",
    "南京": "Nam Kinh",
    "长沙": "Trường Sa",
    "重庆": "Trùng Khánh",
    "西安": "Tây An",
    "丁先生": "Ông Đinh",
    "通过本站已预约......": "Đã đặt lịch qua trang web...",
    "朱先生": "Ông Chu",
    "正在服务中......": "Đang phục vụ...",
    "李先生": "Ông Lý",
    "郭先生": "Ông Quách",
    "路先生": "Ông Lộ",
    "郝先生": "Ông Hạo",
    "赵先生": "Ông Triệu",
    "祝先生": "Ông Chúc",
    "牛先生": "Ông Ngưu",
    "张先生": "Ông Trương",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "Liên hệ và hẹn giờ tốt để tôi đến nhà cô ấy, da vẫn rất mịn màng",
    "颜值很高，服务很专业，还会热舞...": "Giá trị nhan sắc rất cao, dịch vụ rất chuyên nghiệp, và còn biết nhảy múa nữa...",
    "床上满分 可一字马 性格超好 氵超多": "Trên giường 10 điểm, có thể làm động tác một chân, tính cách tốt, nhiều kinh nghiệm",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "Được giới thiệu bởi bạn bè, cô ấy trẻ và xinh đẹp, dịch vụ đủ phong cách Đông Quan.",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "Hôm nay tôi có thời gian rảnh rỗi, quyết định kiểm tra một chút và cuối cùng đã hẹn hò. Khi gặp nhau, cô ấy có vòng 1 vừa phải, thân hình đẹp và giống hình ảnh trên ảnh.",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "Khi tôi bước vào, cô ấy trông như một phụ nữ trưởng thành nhẹ, tôi thích phụ nữ trưởng thành, khi cô ấy mặc giày cao gót, thân hình cô ấy nổi bật hơn. Sau một lúc nói chuyện, chúng tôi bắt đầu và dịch vụ blowjob rất thoải mái, tôi cảm thấy sưng và sung sướng.",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "Cô ấy rất tận tâm và quyến rũ, hơi dâm, nói chuyện nhẹ nhàng và có khả năng nói những lời dâm đảng khiến tôi hưng phấn và kích thích.",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "Cô gái trông rất nhỏ tuổi, dịch vụ bình thường, cơ thể hơi béo, nhưng thái độ vẫn tốt. Ai thích người trẻ có thể thử...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "Cô gái có khuôn mặt dễ thương, nốt ruồi đẹp nhất, thân hình tốt, thích du lịch, tính cách dịu dàng và vui vẻ",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "Cô gái khá trẻ, đẹp và da hơi đen. Cô ấy mới trở lại Shanghai gần đây và đã thực hiện dịch vụ massage cơ bản.",
    "登录": "Đăng nhập",
    "请输入用户名": "Vui lòng nhập tên người dùng",
    "请输入密码": "Vui lòng nhập mật khẩu",
    "忘记密码？": "Quên mật khẩu?",
    "没有账户？马上注册": "Chưa có tài khoản? Đăng ký ngay",
    "注册": "Đăng ký",
    "请输入用户名(6-12个英文字母或数字)": "Vui lòng nhập tên người dùng (6-12 ký tự bằng chữ hoặc số)",
    "请输入登录密码(6-12个英文字母或数字)": "Vui lòng nhập mật khẩu đăng nhập (6-12 ký tự bằng chữ hoặc số)",
    "我已经知晓并同意'开户协议'各项条约": "Tôi đã biết và đồng ý với các điều khoản của 'Hợp đồng mở tài khoản'",
    "请输入邀请码": "Vui lòng nhập mã mời",
    "请勾选下方开户协议": "Vui lòng chọn hợp đồng mở tài khoản ở phía dưới",
    "预约做单": "Đặt lịch làm việc",
    "距 第": "Còn lại ",
    "期 截止": " kỳ",
    "第": "Kỳ thứ",
    "期": "",
    "糖心": "Tăng",
    "烟花": "Pháo hoa",
    "双份": "Gấp đôi",
    "单份": "Đơn",
    "双": "Gấp đôi",
    "单": "Đơn",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "Dữ liệu này được sử dụng để xếp hạng các ngôi sao nổi tiếng trong phòng trực tiếp dựa trên số tiền thưởng, và khách hàng chính thức sẽ được phép mở phòng trực tuyến miễn phí sau khi hoàn thành.",
    "与主播私下约啪": "Hẹn gặp riêng với người dẫn chương trình",
    "任务单": "Danh sách nhiệm vụ",
    "可用余额": "Số dư có thể sử dụng",
    "元": "Đồng",
    "打赏": "Thưởng",
    "当前选号": "Số hiện tại",
    "打赏金额": "Số tiền thưởng",
    "请输入金额": "Vui lòng nhập số tiền",
    "总共打赏": "Tổng số tiền thưởng",
    "合计": "Tổng",
    "清空订单": "Xóa đơn hàng",
    "确认打赏": "Xác nhận thưởng",
    "确认": "Xác nhận",
    "期号": "Số kỳ",
    "正确打赏": "Thưởng đúng",
    "约炮数据一": "Dữ liệu hẹn hò 1",
    "约炮数据二": "Dữ liệu hẹn hò 2",
    "约炮数据三": "Dữ liệu hẹn hò 3",
    "高级VIP数据": "Dữ liệu VIP cao cấp",
    "填写收款卡": "Điền thông tin thẻ nhận tiền",
    "请输入您的收款卡信息": "Vui lòng nhập thông tin thẻ nhận tiền của bạn",
    "请输入真实银行卡号": "Vui lòng nhập số thẻ ngân hàng thật của bạn",
    "请选择银行": "Vui lòng chọn ngân hàng",
    "银行卡号": "Số thẻ ngân hàng",
    "银行名称": "Tên ngân hàng",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "Kính thưa quý khách, để bảo vệ sự an toàn tài chính của bạn, vui lòng xác nhận tên thật của bạn và thiết lập mật khẩu rút tiền. Nếu tên không trùng khớp với tên tài khoản, bạn sẽ không thể rút tiền.",
    "确认提现": "Xác nhận rút tiền",
    "任务记录": "Lịch sử nhiệm vụ",
    "数据为空": "Dữ liệu trống",
    "打赏积分": "Điểm thưởng",
    "下单时间": "Thời gian đặt hàng",
    "结算时间": "Thời gian thanh toán",
    "充值": "Nạp tiền",
    "提现": "Rút tiền",
    "我的钱包": "Ví của tôi",
    "详情": "Chi tiết",
    "余额(元)": "Số dư(đồng)",
    "个人报表": "Báo cáo cá nhân",
    "账户明细": "Chi tiết tài khoản",
    "打赏记录": "Lịch sử thưởng",
    "个人中心": "Trung tâm cá nhân",
    "信息公告": "Thông báo",
    "在线客服": "Dịch vụ trực tuyến",
    "请完成任务单后进入": "Vui lòng hoàn thành nhiệm vụ trước khi tiếp tục",
    "请联系客服充值": "Vui lòng liên hệ với dịch vụ khách hàng để nạp tiền",
    "请设置收款卡": "Vui lòng thiết lập thẻ nhận tiền",
    "功能已禁用": "Chức năng đã bị tắt",
    "登录/注册": "Đăng nhập/Đăng ký",
    "登录可享受更多服务": "Đăng nhập để trải nghiệm thêm nhiều dịch vụ hơn",
    "基本信息": "Thông tin cơ bản",
    "头像": "Hình đại diện",
    "选择头像": "Chọn hình đại diện",
    "确定": "Xác nhận",
    "真实姓名": "Tên thật",
    "未设置": "Chưa thiết lập",
    "性别": "Giới tính",
    "男": "Nam",
    "女": "Nữ",
    "未知": "Không rõ",
    "绑定信息": "Thông tin liên kết",
    "已绑定": "Đã liên kết",
    "无": "Không",
    "系统公告": "Thông báo hệ thống",
    "盈利金额(元)": "Số tiền lời (đồng)",
    "盈利计算公式 : 盈利金额 - 任务金额": "Công thức tính lời: Số tiền lời - Số tiền nhiệm vụ",
    "任务金额": "Số tiền nhiệm vụ",
    "充值金额": "Số tiền nạp",
    "提现金额": "Số tiền rút",
    "中奖金额": "Số tiền thắng",
    "蜜獾娱乐": "Giải trí Mihuan",
    "联系": "Liên hệ",
    "全天7 * 24小时竭诚为您服务": "Dịch vụ 24/7 sẵn sàng phục vụ bạn",
    "收款卡信息": "Thông tin thẻ nhận tiền",
    "添加收款卡": "Thêm thẻ nhận tiền",
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "Lời nhắc: Vui lòng thiết lập ngân hàng thương mại lớn, nếu cần chỉnh sửa, vui lòng liên hệ với dịch vụ trực tuyến của chúng tôi",
    "请设置姓名后再绑定银行卡": "Vui lòng thiết lập tên sau đó mới liên kết thẻ ngân hàng",
    "请设置提现密码后再绑定银行卡": "Vui lòng thiết lập mật khẩu rút tiền trước sau đó mới liên kết thẻ ngân hàng",
    "修改登录密码": "Sửa mật khẩu đăng nhập",
    "保存": "Lưu",
    "旧密码": "Mật khẩu cũ",
    "请输入您的旧密码": "Vui lòng nhập mật khẩu cũ của bạn",
    "新密码": "Mật khẩu mới",
    "请输入您的新密码": "Vui lòng nhập mật khẩu mới của bạn",
    "请填写完整": "Vui lòng điền đầy đủ thông tin",
    "两次密码输入不一致": "Hai lần nhập mật khẩu không giống nhau",
    "修改真实姓名": "Sửa tên thật",
    "姓名": "Tên",
    "请输入真实姓名": "Vui lòng nhập tên thật của bạn",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "Để đảm bảo an toàn tài khoản của bạn, tên thật cần phải trùng khớp với tên trên thẻ ngân hàng",
    "请勿重复设置": "Vui lòng không thiết lập trùng lặp",
    "请输入姓名": "Vui lòng nhập tên",
    "设置资金密码": "Thiết lập mật khẩu tài chính",
    "请输入资金密码": "Vui lòng nhập mật khẩu tài chính của bạn",
    "请再次输入资金密码": "Vui lòng nhập lại mật khẩu tài chính",
    "两次密码不一致": "Hai lần nhập mật khẩu không giống nhau",
    "性别修改": "Chỉnh sửa giới tính",
    "设置": "Thiết lập",
    "基本信息设置": "Thiết lập thông tin cơ bản",
    "登录密码": "Mật khẩu đăng nhập",
    "资金密码": "Mật khẩu tài chính",
    "退出登录": "Đăng xuất",
    "提现密码已设置，需要修改请联系客服": "Mật khẩu rút tiền đã được thiết lập, nếu cần chỉnh sửa, vui lòng liên hệ với dịch vụ khách hàng của chúng tôi",
    "已设置": "Đã thiết lập",
    "提现中心": "Trung tâm rút tiền",
    "提现记录": "Lịch sử rút tiền",
    "提现金额 (元)": "Số tiền rút tiền (đồng)",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "Thời gian nhận tiền: Thông thường, thời gian nhận tiền khoảng 5 phút, nhanh nhất là 2 phút",
    "说明": "Giải thích",
    "余额": "Số dư",
    "马上提现": "Rút tiền ngay",
    "请填写正确的金额": "Vui lòng nhập số tiền đúng",
    "金额": "Số tiền",
    "提交时间": "Thời gian gửi",
    "审核时间": "Thời gian kiểm tra",
    "没有更多了": "Không còn nữa",
    "播放": "Phát",
    "次播放": "lượt xem",
    "热门推荐": "Đề xuất phổ biến",
    "账号下线": "Tài khoản đăng xuất",
    "请充值后观看视频": "Vui lòng nạp tiền trước khi xem video",
    "直播间": "Phòng trực tiếp",
    "官方认证女神": "Nữ thần được chứng nhận chính thức",
    "已缴纳20000保证金": "Đã đặt cọc 20.000 đồng",
    "地区": "Khu vực",
    "签名": "Chữ ký",
    "获取联系方式": "Nhận thông tin liên hệ",
    "温馨提示": "Lời nhắc ấm áp",
    "联系数据老师激活权限": "Liên hệ giáo viên dữ liệu để kích hoạt quyền hạn",
    "取消": "Hủy",
    "标题": "Tiêu đề",
    "首页": "Trang chủ",
    "预约": "Đặt hẹn",
    "我的": "Của tôi",
    "注": "Ghi chú",
    "金额错误": "Sai số tiền",
    "请选号": "Vui lòng chọn số",
    "请填写金额": "Vui lòng nhập số tiền",
    "余额不足，请联系客服充值": "Số dư không đủ, vui lòng liên hệ với dịch vụ khách hàng để nạp tiền",
    "请联系管理员领取该任务": "Vui lòng liên hệ với quản trị viên để nhận nhiệm vụ này",
    "开奖成功，期号": "Kết quả mở thưởng thành công, số kỳ",
    "玫瑰": "Hoa hồng",
    "火箭": "Rakét",
    "添加转数快": "Thêm tài khoản chuyển tiền nhanh",
    "填写转数快": "Nhập thông tin tài khoản chuyển tiền nhanh",
    "请输入您的转数快信息": "Vui lòng nhập thông tin tài khoản chuyển tiền nhanh của bạn",
    "联系电话": "Số điện thoại liên hệ",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合": "Theo yêu cầu kiểm soát rủi ro của Ngân hàng Liên minh, trong vòng 24 giờ, không được phép xác minh thẻ quá 4 lần, xin cảm ơn sự hợp tác của bạn",
    "已存在提款方式，请勿重复绑定": "Phương thức rút tiền đã tồn tại, vui lòng không liên kết trùng lặp",
}