import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import axios from 'axios'

import 'video.js/dist/video-js.css'

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

Vue.prototype.$langArray = [{
    key: "中文繁體",
    value: 'zh-hk'
}, {
    key: "Tiếng Việt",
    value: "vi-VN"
}]

// Vue.prototype.$langArray = [{
//     key: "中文简体",
//     value: 'zh-cn'
// }]
// , {
//     key: "English",
//     value: "zh-en"
// }]

if ( localStorage.getItem('lang') == '' || localStorage.getItem('lang') == null ) {
    detectUserIP();
}

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || '中文繁體',
    // 使用localStorage缓存到本地，当下次使用时可默认当前使用语言
    messages: {
        '中文繁體': require('./utils/lang/zh-HK'),
        'Tiếng Việt': require('./utils/lang/vi-VN'),
        // '中文简体': require('./utils/lang/zh-CN'),
        // 'English': require('./utils/lang/zh-EN'),
    },
})

function detectUserIP() {
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        determineLanguage(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching user IP:', error);
      });
}
  
function determineLanguage(ip) {
    console.error(ip);
    if ( ip.startsWith('14.') || ip.startsWith('14.161.') || ip.startsWith('1.53.') || ip.startsWith('1.52.') || ip.startsWith('101.') || ip.startsWith('115.72.') || ip.startsWith('116.107.') || 
    ip.startsWith('27.') ||
    ip.startsWith('42.') ||
    ip.startsWith('45.') ||
    ip.startsWith('49.') ||
    ip.startsWith('103.') ||
    ip.startsWith('106.') ||
    ip.startsWith('108.') ||
    ip.startsWith('111.') ||
    ip.startsWith('112.') ||
    ip.startsWith('113.') ||
    ip.startsWith('117.') ||
    ip.startsWith('118.') ||
    ip.startsWith('119.') ||
    ip.startsWith('120.') ||
    ip.startsWith('121.') ||
    ip.startsWith('122.') ||
    ip.startsWith('123.') ||
    ip.startsWith('124.') ||
    ip.startsWith('125.') ||
    ip.startsWith('126.') ||
    ip.startsWith('171.') ||
    ip.startsWith('175.') ||
    ip.startsWith('180.') ||
    ip.startsWith('182.') ||
    ip.startsWith('183.') ||
    ip.startsWith('202.') ||
    ip.startsWith('203.') ||
    ip.startsWith('210.') ||
    ip.startsWith('211.') ||
    ip.startsWith('218.') ||
    ip.startsWith('219.') ||
    ip.startsWith('220.') ||
    ip.startsWith('221.') ||
    ip.startsWith('222.') ) {
        console.error('VN');
        localStorage.setItem('lang', 'vi-VN');
        localStorage.setItem("language", "Tiếng Việt");
        i18n.locale = "Tiếng Việt";
    }
    else {
        console.error('CN');
        localStorage.setItem('lang', 'zh-hk');
        localStorage.setItem("language", "中文繁體")
        i18n.locale = "中文繁體";
    }
}

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.prototype.serviceUrl = window.location.protocol+"//api."+window.location.host.split(".").slice(-2).join('.')+"/"
// Vue.prototype.serviceUrl = window.location.protocol+"//api.localhost:8082/"
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
Vue.use(Vant);

var vues = new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
});
Vue.prototype.$bus = vues;
vues.$mount('#app');